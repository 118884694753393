import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Datagrid,
  TextField,
  DateField,
  required,
  SaveButton,
  Toolbar,
} from 'react-admin';

export const CityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by city name" source="city" alwaysOn />
  </Filter>
);

export const CityEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CityList = (props) => (
  <List {...props} bulkActionButtons={false} filters={<CityFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="city" />
      <ReferenceField label="State" source="stateId" reference="states">
        <TextField source="abbr" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const CityCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="city" />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'state', order: 'ASC' }}
        label="State"
        source="stateId"
        reference="states"
      >
        <SelectInput optionValue="id" optionText="abbr" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const CityEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CityEditToolbar />}>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="city" validate={required()} />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'state', order: 'ASC' }}
        label="State"
        source="stateId"
        reference="states"
      >
        <SelectInput optionValue="id" optionText="abbr" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
