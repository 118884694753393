import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  TopToolbar,
  required,
  ExportButton,
} from 'react-admin';

import { ImportButton } from 'react-admin-import-csv';
import { CreateButton } from 'ra-ui-materialui';

const ListActions = (props) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportButton {...props} />
      <ExportButton />
    </TopToolbar>
  );
};

export const RepFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by first name" source="firstName" alwaysOn />
    <TextInput label="Search by last name" source="lastName" alwaysOn />
  </Filter>
);

export const RepList = (props) => (
  <List {...props} actions={<ListActions />} filters={<RepFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="status" />
      <TextField source="accountStatus" />
      <TextField source="customerId" />
      <TextField source="subscriptionId" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const RepCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="backupRepFirstName" />
      <TextInput source="backupRepLastName" />
      <NumberInput source="backupRepId" />
      <TextInput source="companyEmail" />
      <TextInput source="customerId" />
      <TextInput source="customerServicePhone" />
      <TextInput source="email" validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <BooleanInput source="isPremiumFreeTrial" />
      <TextInput
        label="company DUNS (needed only for free trials)"
        source="company_duns"
      />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'title', order: 'ASC' }}
        label="Title"
        source="reptitleId"
        reference="reptitles"
      >
        <SelectInput optionValue="id" optionText="title" />
      </ReferenceInput>
      <BooleanInput source="isAccountSelected" />
      <BooleanInput source="isOtpVerified" />
      <BooleanInput source="isSpecialtySelected" />
      <NumberInput source="otp" />
      <TextInput source="passwordResetToken" />
      <TextInput source="phone" />
      <TextInput source="photoUrl" />
      <TextInput source="status" />
      <TextInput source="subscriptionId" />
      <TextInput source="website" />
    </SimpleForm>
  </Create>
);

export const RepEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput
        allowEmpty
        source="accountStatus"
        choices={[
          { id: 'closed', name: 'Closed' },
          { id: 'active', name: 'Active' },
        ]}
      />
      <BooleanInput source="active" />
      <TextInput source="backupRepFirstName" />
      <TextInput source="backupRepLastName" />
      <NumberInput source="backupRepId" />
      <TextInput source="companyEmail" />
      <TextInput source="customerId" />
      <TextInput source="customerServicePhone" />
      <TextInput source="email" validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'title', order: 'ASC' }}
        label="Title"
        source="reptitleId"
        reference="reptitles"
        validate={required()}
      >
        <SelectInput optionValue="id" optionText="title" />
      </ReferenceInput>
      <BooleanInput source="isAccountSelected" />
      <BooleanInput source="isOtpVerified" />
      <BooleanInput source="isSpecialtySelected" />
      <NumberInput source="otp" />
      <TextInput source="passwordResetToken" />
      <TextInput source="phone" />
      <TextInput source="photoUrl" />
      <TextInput source="status" />
      <TextInput source="subscriptionId" />
      <TextInput source="website" />
    </SimpleForm>
  </Edit>
);
