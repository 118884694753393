import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  required,
  BooleanField,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';

export const SuppliersList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField
        label="Manufacturer"
        source="manufacturerId"
        reference="manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const SuppliersCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ReferenceInput
        label="Manufacturer"
        source="manufacturerId"
        reference="manufacturers"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const SuppliersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ReferenceInput
        label="Manufacturer"
        source="manufacturerId"
        reference="manufacturers"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
