import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  ReferenceField,
  ReferenceInput,
  Datagrid,
  TextField,
  DateField,
  AutocompleteInput,
  required,
} from 'react-admin';

const cityOptionRenderer = (city) => `${city.city}, ${city.stateAbbr}`;

export const HospitalFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by hospital name" source="hospital" alwaysOn />
  </Filter>
);

export const HospitalList = (props) => (
  <List {...props} filters={<HospitalFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="hospital" />
      <TextField source="website" />
      <ReferenceField label="City" source="cityId" reference="cities">
        <TextField source="city" />
      </ReferenceField>
      <ReferenceField
        label="State"
        source="cityId"
        reference="cities"
        link={false}
      >
        <TextField source="stateAbbr" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const HospitalCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="hospital" validate={required()} />
      <TextInput source="website" />
      <ReferenceInput
        sort={{ field: 'city', order: 'ASC' }}
        label="City"
        source="cityId"
        reference="cities"
        filterToQuery={(searchText) => ({ city: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionValue="id" optionText={cityOptionRenderer} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const HospitalEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="hospital" validate={required()} />
      <TextInput source="website" />
      <ReferenceInput
        sort={{ field: 'city', order: 'ASC' }}
        label="City"
        source="cityId"
        reference="cities"
        filterToQuery={(searchText) => ({ city: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionValue="id" optionText={cityOptionRenderer} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
