import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const ProviderFavoriteRepList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        label="Provider"
        source="providerId"
        reference="providers"
      >
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="Rep" source="repId" reference="reps">
        <TextField source="lastName" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const ProviderFavoriteRepCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
      <ReferenceInput
        label="Rep"
        source="repId"
        reference="reps"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ProviderFavoriteRepEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
      <ReferenceInput
        label="Rep"
        source="repId"
        reference="reps"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
