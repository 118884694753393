import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  ReferenceField,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  BooleanInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  required,
} from 'react-admin';

export const HospitalRepFilter = (props) => (
  <Filter {...props}>
    <ReferenceField label="Hospital" source="hospitalId" reference="hospitals">
      <TextInput label="Search by hospital name" source="hospital" alwaysOn />
    </ReferenceField>
  </Filter>
);

export const HospitalRepList = (props) => (
  <List {...props} filters={<HospitalRepFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
      >
        <TextField source="hospital" />
      </ReferenceField>
      <ReferenceField label="Rep" source="repId" reference="reps">
        <FunctionField
          render={(rep) => `${rep.firstName} ${rep.lastName} (${rep.email})`}
        />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const HospitalRepCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="admin" />
      <DateInput source="fromDate" />
      <DateInput source="toDate" />
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const HospitalRepEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="admin" />
      <DateInput source="fromDate" />
      <DateInput source="toDate" />
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
