import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  required,
  SaveButton,
  Toolbar,
} from 'react-admin';

export const StateEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const StateList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="state" />
      <TextField source="abbr" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const StateCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="state" validate={required()} />
      <TextInput source="abbr" validate={required()} />
    </SimpleForm>
  </Create>
);

export const StateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<StateEditToolbar />}>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="state" validate={required()} />
      <TextInput source="abbr" validate={required()} />
    </SimpleForm>
  </Edit>
);
