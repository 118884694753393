import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin';

export const HospitalEmailDomainList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
      >
        <TextField source="hospital" />
      </ReferenceField>
      <ReferenceField
        label="Email Domain"
        source="emaildomainId"
        reference="emaildomains"
      >
        <TextField source="email_domain" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const HospitalEmailDomainCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Email Domain"
        source="emaildomainId"
        reference="emaildomains"
        filterToQuery={(searchText) => ({ email_domain: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="email_domain" />
      </ReferenceInput>
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const HospitalEmailDomainEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Email Domain"
        source="emaildomainId"
        reference="emaildomains"
        filterToQuery={(searchText) => ({ email_domain: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="email_domain" />
      </ReferenceInput>
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
