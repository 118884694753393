import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  TopToolbar,
  required,
  ExportButton,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from 'react-admin';

import { ImportButton } from 'react-admin-import-csv';
import { CreateButton } from 'ra-ui-materialui';

const ListActions = (props) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportButton {...props} />
      <ExportButton />
    </TopToolbar>
  );
};

export const SupplierAdminFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by first name" source="firstName" alwaysOn />
    <TextInput label="Search by last name" source="lastName" alwaysOn />
  </Filter>
);

export const SupplierAdminList = (props) => (
  <List {...props} actions={<ListActions />} filters={<SupplierAdminFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <ReferenceField
        label="Supplier"
        source="supplierId"
        reference="suppliers"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <DateField source="deletedAt" showTime />
    </Datagrid>
  </List>
);

export const SupplierAdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Supplier"
        source="supplierId"
        reference="suppliers"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="email" validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
    </SimpleForm>
  </Create>
);

export const SupplierAdminEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="email" validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
    </SimpleForm>
  </Edit>
);
