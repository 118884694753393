import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  BooleanInput,
  required,
} from 'react-admin';

export const ManufacturerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
    <TextInput label="Search by DUNS" source="duns" alwaysOn />
    <TextInput label="Search by parent DUNS" source="parent_duns" alwaysOn />
  </Filter>
);

export const ManufacturerList = (props) => (
  <List {...props} filters={<ManufacturerFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="duns" />
      <TextField source="parent_duns" />
      <BooleanField source="is_parent" />
      <TextField source="website" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const ManufacturerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="customerServicePhone" />
      <TextInput source="designation" />
      <TextInput source="duns" />
      <TextInput source="name" validate={required()} />
      <TextInput source="parent_duns" />
      <BooleanInput source="is_parent" />
      <TextInput source="website" />
    </SimpleForm>
  </Create>
);

export const ManufacturerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="customerServicePhone" />
      <TextInput source="designation" />
      <TextInput source="duns" validate={required()} />
      <TextInput source="name" validate={required()} />
      <TextInput source="parent_duns" />
      <BooleanInput source="is_parent" />
      <TextInput source="website" />
    </SimpleForm>
  </Edit>
);
