import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  required,
} from 'react-admin';

export const ProviderSpecialtyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const ProviderSpecialtyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export const ProviderSpecialtyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
