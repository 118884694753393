import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateInput,
  NumberInput,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  required,
} from 'react-admin';

import { ImportButton } from 'react-admin-import-csv';
import { CreateButton } from 'ra-ui-materialui';

const ListActions = (props) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportButton {...props} />
    </TopToolbar>
  );
};

export const ProviderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by first name" source="firstName" alwaysOn />
    <TextInput label="Search by last name" source="lastName" alwaysOn />
  </Filter>
);

export const ProviderList = (props) => (
  <List {...props} actions={<ListActions />} filters={<ProviderFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="status" />
      <TextField source="customerId" />
      <TextField source="subscriptionId" />
      <NumberField source="otp" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const ProviderCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="customerId" />
      <TextInput source="email" validate={required()} />
      <TextInput source="favoriteRep" />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <BooleanInput source="isPremiumFreeTrial" />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'title', order: 'ASC' }}
        label="Title"
        source="providertitleId"
        reference="providertitles"
      >
        <SelectInput optionValue="id" optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        perPage={100}
        sort={{ field: 'name', order: 'ASC' }}
        label="Specialty"
        source="providersspecialtyId"
        reference="providersSpecialties"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <DateInput source="lastLogin" />
      <NumberInput source="otp" />
      <TextInput source="passwordResetToken" />
      <TextInput source="phone" />
      <TextInput source="photoUrl" />
      <TextInput source="status" />
      <TextInput source="subscriptionId" />
    </SimpleForm>
  </Create>
);

export const ProviderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="customerId" />
      <TextInput source="email" validate={required()} />
      <TextInput source="favoriteRep" />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <ReferenceInput
        perPage={100}
        sort={{ field: 'title', order: 'ASC' }}
        label="Title"
        source="providertitleId"
        reference="providertitles"
        validate={required()}
      >
        <SelectInput optionValue="id" optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        perPage={100}
        sort={{ field: 'name', order: 'ASC' }}
        label="Specialty"
        source="providersspecialtyId"
        reference="providersSpecialties"
        validate={required()}
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <DateInput source="lastLogin" />
      <NumberInput source="otp" />
      <TextInput source="passwordResetToken" />
      <TextInput source="phone" />
      <TextInput source="photoUrl" />
      <TextInput source="status" />
      <TextInput source="subscriptionId" />
    </SimpleForm>
  </Edit>
);
