// in src/App.js
import Amplify, { Auth } from 'aws-amplify';
import { AuthProvider, Login } from 'ra-cognito';
import React from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import restServerProvider from 'ra-data-simple-rest';
import './App.css';
import { CityList, CityCreate, CityEdit } from './components/city';
import { DeviceList, DeviceCreate, DeviceEdit } from './components/device';

import {
  BlockedEmailDomainList,
  BlockedEmailDomainCreate,
  BlockedEmailDomainEdit,
} from './components/blockedEmailDomain';
import {
  GMDNTermList,
  GMDNTermCreate,
  GMDNTermEdit,
} from './components/gmdnTerm';
import {
  DistributorList,
  DistributorCreate,
  DistributorEdit,
} from './components/distributor';
import {
  DistributorEmailDomainList,
  DistributorEmailDomainCreate,
  DistributorEmailDomainEdit,
} from './components/distributorsEmailDomain';
import {
  EmailDomainList,
  EmailDomainCreate,
  EmailDomainEdit,
} from './components/emailDomain';
import {
  HospitalList,
  HospitalCreate,
  HospitalEdit,
} from './components/hospital';
import {
  HospitalEmailDomainList,
  HospitalEmailDomainCreate,
  HospitalEmailDomainEdit,
} from './components/hospitalEmailDomain';
import {
  HospitalProviderList,
  HospitalProviderCreate,
  HospitalProviderEdit,
} from './components/hospitalProvider';
import {
  HospitalRepList,
  HospitalRepCreate,
  HospitalRepEdit,
} from './components/hospitalRep';
import {
  ManufacturerList,
  ManufacturerCreate,
  ManufacturerEdit,
} from './components/manufacturer';
import {
  ManufacturerEmailDomainList,
  ManufacturerEmailDomainCreate,
  ManufacturerEmailDomainEdit,
} from './components/manufacturerEmailDomain';
import {
  ProductLineList,
  ProductLineCreate,
  ProductLineEdit,
} from './components/productLine';
import {
  ProviderList,
  ProviderCreate,
  ProviderEdit,
} from './components/provider';
import {
  ProviderFavoriteRepList,
  ProviderFavoriteRepCreate,
  ProviderFavoriteRepEdit,
} from './components/providerFavoriteRep';
import {
  ProviderSpecialtyList,
  ProviderSpecialtyCreate,
  ProviderSpecialtyEdit,
} from './components/providerSpecialty';
import {
  ProviderTitlesList,
  ProviderTitlesCreate,
  ProviderTitlesEdit,
} from './components/providerTitles';
import {
  RecentSearchLogList,
  RecentSearchLogCreate,
  RecentSearchLogEdit,
} from './components/recentSearchLog';
import { RepList, RepCreate, RepEdit } from './components/rep';
import {
  RepDistributorList,
  RepDistributorCreate,
  RepDistributorEdit,
} from './components/repDistributor';
import {
  RepManufacturerList,
  RepManufacturerCreate,
  RepManufacturerEdit,
} from './components/repManufacturer';
import {
  RepProductLineList,
  RepProductLineCreate,
  RepProductLineEdit,
} from './components/repProductLine';
import {
  RepTitlesList,
  RepTitlesCreate,
  RepTitlesEdit,
} from './components/repTitles';
import {
  ProductCategoryList,
  ProductCategoryCreate,
  ProductCategoryEdit,
} from './components/productCategory';
import { StateList, StateCreate, StateEdit } from './components/state';
import {
  SuppliersList,
  SuppliersCreate,
  SuppliersEdit,
} from './components/suppliers';
import {
  SupplierAdminList,
  SupplierAdminCreate,
  SupplierAdminEdit,
} from './components/supplierAdmin';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;
  options.headers.set('Authorization', jwtToken);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = restServerProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={AuthProvider}
    loginPage={Login}
  >
    <Resource
      name="blockedEmaildomains"
      list={BlockedEmailDomainList}
      create={BlockedEmailDomainCreate}
      edit={BlockedEmailDomainEdit}
      options={{ label: 'Blocked Email Domains' }}
    />
    <Resource
      name="cities"
      list={CityList}
      create={CityCreate}
      edit={CityEdit}
    />
    <Resource
      name="devices"
      list={DeviceList}
      create={DeviceCreate}
      edit={DeviceEdit}
    />
    <Resource
      name="distributors"
      list={DistributorList}
      create={DistributorCreate}
      edit={DistributorEdit}
    />
    <Resource
      name="distributorsEmailDomains"
      list={DistributorEmailDomainList}
      create={DistributorEmailDomainCreate}
      edit={DistributorEmailDomainEdit}
      options={{ label: 'Distributors Email Domains' }}
    />
    <Resource
      name="emaildomains"
      list={EmailDomainList}
      create={EmailDomainCreate}
      edit={EmailDomainEdit}
      options={{ label: 'Email Domains' }}
    />
    <Resource
      name="gmdnTerms"
      list={GMDNTermList}
      create={GMDNTermCreate}
      edit={GMDNTermEdit}
      options={{ label: 'GMDN Terms' }}
    />
    <Resource
      name="hospitalReps"
      list={HospitalRepList}
      create={HospitalRepCreate}
      edit={HospitalRepEdit}
      options={{ label: 'Hospital Reps' }}
    />
    <Resource
      name="hospitals"
      list={HospitalList}
      create={HospitalCreate}
      edit={HospitalEdit}
    />
    <Resource
      name="hospitalsEmailDomains"
      list={HospitalEmailDomainList}
      create={HospitalEmailDomainCreate}
      edit={HospitalEmailDomainEdit}
      options={{ label: 'Hospitals Email Domains' }}
    />
    <Resource
      name="hospitalProviders"
      list={HospitalProviderList}
      create={HospitalProviderCreate}
      edit={HospitalProviderEdit}
      options={{ label: 'Hospital Providers' }}
    />
    <Resource
      name="manufacturers"
      list={ManufacturerList}
      create={ManufacturerCreate}
      edit={ManufacturerEdit}
    />
    <Resource
      name="manufacturersEmailDomains"
      list={ManufacturerEmailDomainList}
      create={ManufacturerEmailDomainCreate}
      edit={ManufacturerEmailDomainEdit}
      options={{ label: 'Manufacturer Email Domains' }}
    />
    <Resource
      name="productcategories"
      list={ProductCategoryList}
      create={ProductCategoryCreate}
      edit={ProductCategoryEdit}
      options={{ label: 'Product Categories' }}
    />
    <Resource
      name="productLines"
      list={ProductLineList}
      create={ProductLineCreate}
      edit={ProductLineEdit}
      options={{ label: 'Product Lines' }}
    />
    <Resource
      name="providerFavorites"
      list={ProviderFavoriteRepList}
      create={ProviderFavoriteRepCreate}
      edit={ProviderFavoriteRepEdit}
      options={{ label: 'Provider Favorites' }}
    />
    <Resource
      name="providers"
      list={ProviderList}
      create={ProviderCreate}
      edit={ProviderEdit}
    />
    <Resource
      name="providertitles"
      list={ProviderTitlesList}
      create={ProviderTitlesCreate}
      edit={ProviderTitlesEdit}
      options={{ label: 'Provider Titles' }}
    />
    <Resource
      name="providersSpecialties"
      list={ProviderSpecialtyList}
      create={ProviderSpecialtyCreate}
      edit={ProviderSpecialtyEdit}
      options={{ label: 'Provider Specialties' }}
    />
    <Resource
      name="recentSearchLogs"
      list={RecentSearchLogList}
      create={RecentSearchLogCreate}
      edit={RecentSearchLogEdit}
      options={{ label: 'Recent Search Logs' }}
    />
    <Resource name="reps" list={RepList} create={RepCreate} edit={RepEdit} />
    <Resource
      name="reptitles"
      list={RepTitlesList}
      create={RepTitlesCreate}
      edit={RepTitlesEdit}
      options={{ label: 'Rep Titles' }}
    />
    <Resource
      name="repDistributers"
      list={RepDistributorList}
      create={RepDistributorCreate}
      edit={RepDistributorEdit}
      options={{ label: 'Rep Distributors' }}
    />
    <Resource
      name="repManufacturers"
      list={RepManufacturerList}
      create={RepManufacturerCreate}
      edit={RepManufacturerEdit}
      options={{ label: 'Rep Manufacturers' }}
    />
    <Resource
      name="repProductLines"
      list={RepProductLineList}
      create={RepProductLineCreate}
      edit={RepProductLineEdit}
      options={{ label: 'Rep Product Lines' }}
    />
    <Resource
      name="states"
      list={StateList}
      create={StateCreate}
      edit={StateEdit}
    />
    <Resource
      name="suppliers"
      list={SuppliersList}
      create={SuppliersCreate}
      edit={SuppliersEdit}
      options={{ label: 'Suppliers' }}
    />
    <Resource
      name="supplierAdmins"
      list={SupplierAdminList}
      create={SupplierAdminCreate}
      edit={SupplierAdminEdit}
      options={{ label: 'Supplier Admins' }}
    />
  </Admin>
);

export default App;
