import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const ProductLineFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </Filter>
);

export const ProductLineList = (props) => (
  <List {...props} filters={<ProductLineFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField
        label="ProductCategory"
        source="productcategoryId"
        reference="productcategories"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const ProductLineCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="info" />
      <TextInput source="name" />
      <ReferenceInput
        label="ProductCategory"
        source="productcategoryId"
        reference="productcategories"
        filterToQuery={(searchText) => ({
          search: { name: searchText },
        })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ProductLineEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="info" />
      <TextInput source="name" />
      <ReferenceInput
        label="ProductCategory"
        source="productcategoryId"
        reference="productcategories"
        filterToQuery={(searchText) => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
