import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  required,
} from 'react-admin';

export const HospitalProviderList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
      >
        <TextField source="hospital" />
      </ReferenceField>
      <ReferenceField
        label="Provider"
        source="providerId"
        reference="providers"
      >
        <FunctionField
          render={(provider) =>
            `${provider.firstName} ${provider.lastName} (${provider.email})`
          }
        />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const HospitalProviderCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="admin" />
      <DateInput source="fromDate" />
      <DateInput source="toDate" />
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const HospitalProviderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="admin" />
      <DateInput source="fromDate" />
      <DateInput source="toDate" />
      <ReferenceInput
        label="Hospital"
        source="hospitalId"
        reference="hospitals"
        filterToQuery={(searchText) => ({ hospital: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="hospital" />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
