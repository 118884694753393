import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  BooleanInput,
  Datagrid,
  TextField,
  DateField,
  required,
} from 'react-admin';

export const EmailDomainFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by email domain" source="email_domain" alwaysOn />
  </Filter>
);

export const EmailDomainList = (props) => (
  <List {...props} filters={<EmailDomainFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="email_domain" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const EmailDomainCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email_domain" validate={required()} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export const EmailDomainEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="email_domain" validate={required()} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
