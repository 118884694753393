import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  required,
} from 'react-admin';

export const DeviceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by brand name" source="brand_name" alwaysOn />
    <TextInput label="Search by company name" source="company_name" alwaysOn />
  </Filter>
);

export const DeviceList = (props) => (
  <List {...props} filters={<DeviceFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="brand_name" />
      <TextField source="comm_distribution_status" />
      <TextField source="company_name" />
      <TextField source="duns_number" />
      <TextField source="primary_di" />
      <TextField source="version_model_number" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const DeviceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="brand_name" validate={required()} />
      <TextInput source="comm_distribution_status" validate={required()} />
      <TextInput source="company_name" validate={required()} />
      <TextInput source="device_description" validate={required()} />
      <TextInput source="duns_number" validate={required()} />
      <TextInput source="primary_di" validate={required()} />
      <TextInput source="version_model_number" validate={required()} />
    </SimpleForm>
  </Create>
);

export const DeviceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="brand_name" validate={required()} />
      <TextInput source="comm_distribution_status" validate={required()} />
      <TextInput source="company_name" validate={required()} />
      <TextInput source="device_description" validate={required()} />
      <TextInput source="duns_number" validate={required()} />
      <TextInput source="primary_di" validate={required()} />
      <TextInput source="version_model_number" validate={required()} />
    </SimpleForm>
  </Edit>
);
