import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  required,
} from 'react-admin';

export const RepProductLineList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="Rep" source="repId" reference="reps">
        <FunctionField
          render={(rep) => `${rep.firstName} ${rep.lastName} (${rep.email})`}
        />
      </ReferenceField>
      <ReferenceField
        label="ProductLine"
        source="productlineId"
        reference="productLines"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const RepProductLineCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Rep"
        source="repId"
        reference="reps"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
      <ReferenceInput
        label="ProductLine"
        source="productlineId"
        reference="productLines"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const RepProductLineEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Rep"
        source="repId"
        reference="reps"
        filterToQuery={(searchText) => ({ lastName: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
      <ReferenceInput
        label="ProductLine"
        source="productlineId"
        reference="productLines"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
