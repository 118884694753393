import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const RecentSearchLogList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="text" />
      <TextField source="type" />
      <TextField source="productcategories" />
      <TextField source="cities" />
      <ReferenceField
        label="Provider"
        source="providerId"
        reference="providers"
      >
        <TextField source="lastName" />
      </ReferenceField>
      <ReferenceField label="Rep" source="repId" reference="reps">
        <TextField source="lastName" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const RecentSearchLogCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="text" />
      <TextInput source="type" />
      <TextInput source="specialties" />
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const RecentSearchLogEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="text" />
      <TextInput source="type" />
      <TextInput source="specialties" />
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        filterToQuery={(searchText) => ({ lastName: searchText })}
      >
        <AutocompleteInput optionText="lastName" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
