import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Datagrid,
  TextField,
  DateField,
  required,
} from 'react-admin';

export const DistributorList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="designation" />
      <TextField source="customerServicePhone" />
      <TextField source="website" />
      <TextField source="active" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const DistributorCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="designation" validate={required()} />
      <TextInput source="customerServicePhone" validate={required()} />
      <TextInput source="website" validate={required()} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export const DistributorEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="designation" validate={required()} />
      <TextInput source="customerServicePhone" validate={required()} />
      <TextInput source="website" validate={required()} />
      <BooleanInput source="active" validate={required()} />
    </SimpleForm>
  </Edit>
);
